import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import R from 'ramda';
import { HeroFullBlock } from '@cotyorg/ccx-organism-hero';
import StandardLayout from '../../templates/StandardLayout';

const HomeComponent = ({ textSections }) => (
  <StandardLayout>
    <div className="hero-full-block--main">
      <HeroFullBlock
        preloadImage
        heroStoreDataKey="heroSection1"
        layout="vertical"
        ctaType="button"
        theme="secondary"
        isUnderlined={false}
      />
    </div>

    <div className="homepage--background">
      <div className="hero-section" data-test="hero-section">
        <HeroFullBlock
          heroStoreDataKey="heroSection2"
          ctaType="button"
          theme="primary"
          fullWidth={false}
          isUnderlined={false}
        />
      </div>

      <div className="hero-section" data-test="hero-section">
        <HeroFullBlock
          heroStoreDataKey="heroSection3"
          isContentFirst
          ctaType="button"
          theme="secondary"
          fullWidth={false}
          isUnderlined={false}
        />
      </div>

      <div className="hero-section" data-test="hero-section">
        <HeroFullBlock
          heroStoreDataKey="heroSection4"
          ctaType="button"
          theme="secondary"
          fullWidth={false}
          isUnderlined={false}
        />
      </div>

      <div className="hero-section" data-test="hero-section">
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="cell xsmall-16 medium-10 medium-offset-3">
              <div className="home-text-section text--center">
                {R.path(['textSection1', 'introduction'], textSections)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </StandardLayout>
);

HomeComponent.propTypes = {
  textSections: PropTypes.objectOf(PropTypes.object),
};

export default connect(({ content }) => ({
  textSections: R.prop('textSections', content),
}))(HomeComponent);
